import React, { useRef } from 'react';
import { useTranslations } from 'next-intl';
import { Button } from '@depop/web-ui-kit/Button';

import { useCurrentUser } from '@/modules/user/useCurrentUser';

type Props = {
  isFollowing?: boolean;
  onButtonClick: () => void;
  isUpdatingFollowCount: boolean;
};

export function FollowsButton({
  isFollowing = false,
  onButtonClick,
  isUpdatingFollowCount,
}: Props) {
  const [isAuthenticated] = useCurrentUser();
  const tShop = useTranslations('shop');
  const modalTrigger = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button
        size="sm"
        color={isFollowing ? 'green' : 'blue'}
        ref={modalTrigger}
        onClick={onButtonClick}
        disabled={isUpdatingFollowCount}
      >
        {isFollowing && isAuthenticated
          ? tShop('Button.Following')
          : tShop('Button.Follow')}
      </Button>
    </>
  );
}
