import { Active, Over } from '@dnd-kit/core';

export function canSort(activeItem: Active, overItem: Over | null) {
  return activeItem && overItem && activeItem?.id !== overItem?.id;
}

export function sortListWithIndices<T>(
  list: T[],
  oldIndex: number,
  newIndex: number
) {
  const sortableList = [...list];

  const [item] = sortableList.splice(oldIndex, 1);
  sortableList.splice(newIndex, 0, item);

  return sortableList;
}
