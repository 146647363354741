'use client';

import React, { useState, useRef } from 'react';
import { MessageIcon } from '@depop/web-ui-kit/Icons/MessageIcon';
import { Modal } from '@depop/web-ui-kit/Modal';
import { useTranslations } from 'next-intl';

import styles from './styles.module.css';

import { ActivityTrackerEventType } from '@/modules/activityTracker/constants.ts';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker.ts';
import { useCurrentUser } from '@/modules/user/useCurrentUser.ts';
import { DownloadAppModal } from '@/components/DownloadAppModal/DownloadAppModal';
import { ExtendedLink } from '@/components/ExtendedLink';
import { useIsViewingOwnShop } from '@/modules/shop/hooks/useIsViewingOwnShop';
import { buildMessagesCreateUrl } from '@/modules/messages/helpers';
import { useProductTracking } from '@/modules/product/hooks/useProductTracking.ts';

type MessageProps = {
  userId?: number;
  productId?: number;
};

export function MessageButton({ userId, productId }: MessageProps) {
  const t = useTranslations('common');
  const [isAuthenticated, currentUser] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });
  const { sendProductInteractionActionATEvent } = useProductTracking();
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const modalTrigger = useRef<HTMLButtonElement>(null);
  const isViewingOwnProfile = useIsViewingOwnShop();

  const url = buildMessagesCreateUrl({ recipientId: userId, productId });

  function handleClick() {
    if (productId) {
      return sendProductInteractionActionATEvent(productId, 'ProductChat');
    }

    return sendActivityTrackerEvent(
      ActivityTrackerEventType.USER_INTERACTION_ACTION,

      {
        interactionType: 'Chat',
        userId,
      }
    );
  }

  function openModal() {
    setIsDownloadModalOpen(true);

    if (productId) {
      sendActivityTrackerEvent(
        ActivityTrackerEventType.PRODUCT_INTERACTION_ACTION,

        {
          interactionType: 'ProductChat',
          productId,
          schemaVersion: '4.0',
        }
      );
    }
  }

  if (isViewingOwnProfile) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <>
        <button
          className={styles.messageButton}
          ref={modalTrigger}
          onClick={openModal}
          aria-haspopup="dialog"
          aria-label={t('NewMessage')}
        >
          <MessageIcon type="message" title="Messages Link" />
        </button>
        <Modal
          isOpen={isDownloadModalOpen}
          setOpen={setIsDownloadModalOpen}
          triggerRef={modalTrigger}
          content={() => <DownloadAppModal />}
        />
      </>
    );
  }

  return (
    <ExtendedLink
      className={styles.messageButton}
      href={url}
      onClick={handleClick}
      role="link"
    >
      <MessageIcon type="message" title="Messages Link" />
    </ExtendedLink>
  );
}
