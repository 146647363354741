import React, { useState } from 'react';
import { PrimaryTabs } from '@depop/web-ui-kit/PrimaryTabs';
import { useTranslations } from 'next-intl';
import { useInfiniteQuery } from '@tanstack/react-query';
import { LoadingBall } from '@depop/web-ui-kit/LoadingBall';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import { FollowsModalRow } from './FollowsModalRow';
import styles from './styles.module.css';

import {
  FollowRole,
  InternalFollowsItem,
  InternalFollowsMeta,
  InternalFollowsResponse,
} from '@/modules/follows/types';
import { TabPanel, TabPanels } from '@/components/TabPanels';
import { RQ_FOLLOWERS, RQ_FOLLOWING } from '@/modules/ReactQuery/cacheKeys';
import { fetchFollows } from '@/modules/follows/api';
import { ErrorMessage } from '@/components/ErrorMessage';
import { InfiniteScroll } from '@/components/InfiniteScroll';
import { AxiosCompatibleResponse } from '@/modules/http/types';

type FollowsProps = {
  isOwnShop: boolean;
  defaultTab?: number;
  sellerId: number;
  shopUsername: string;
};

type TabConfiguration = {
  id: FollowRole;
  value: number;
  label: string;
  hasError: boolean;
  data?: InternalFollowsItem[];
  isSuccess: boolean;
  meta?: InternalFollowsMeta;
  fetchNextPage: () => void;
  isLoading: boolean;
  noResultsMessage: string;
};

function FollowsModal({
  defaultTab = 0,
  isOwnShop,
  sellerId,
  shopUsername,
}: FollowsProps) {
  const [activeTab, setActiveTab] = useState(defaultTab);
  const t = useTranslations('shop');
  const tCommon = useTranslations('common');

  function getFlatmappedFollowList(
    pages?: AxiosCompatibleResponse<InternalFollowsResponse>[]
  ) {
    return pages?.flatMap((page) => page.data.objects) || [];
  }

  const {
    data: followersData,
    isError: fetchFollowersError,
    isSuccess: fetchFollowersSuccess,
    fetchNextPage: fetchNextFollowersPage,
    isLoading: fetchFollowersLoading,
    isFetchingNextPage: isFetchingFollowersNextPage,
  } = useInfiniteQuery({
    queryKey: [RQ_FOLLOWERS, sellerId],
    queryFn: ({ pageParam }) =>
      fetchFollows({
        role: FollowRole.Followers,
        sellerId,
        offset: pageParam,
      }),
    enabled: activeTab === 0,
    initialPageParam: '',
    getNextPageParam: (_, pages) =>
      String(getFlatmappedFollowList(pages)?.length + 1),
  });

  const {
    data: followingData,
    isError: fetchFollowingError,
    isSuccess: fetchFollowingSuccess,
    fetchNextPage: fetchNextFollowingPage,
    isLoading: fetchFollowingLoading,
    isFetchingNextPage: isFetchingFollowingNextPage,
  } = useInfiniteQuery({
    queryKey: [RQ_FOLLOWING, sellerId],
    queryFn: ({ pageParam }) =>
      fetchFollows({
        role: FollowRole.Following,
        sellerId,
        offset: pageParam,
      }),
    enabled: activeTab === 1,
    initialPageParam: '',
    getNextPageParam: (_, pages) =>
      String(getFlatmappedFollowList(pages)?.length + 1),
  });

  const followers = getFlatmappedFollowList(followersData?.pages);
  const followings = getFlatmappedFollowList(followingData?.pages);

  const followsTabsDetails: TabConfiguration[] = [
    {
      id: FollowRole.Followers,
      value: 0,
      label: t('Followers', { count: 2 }),
      hasError: fetchFollowersError,
      data: followers,
      isSuccess: fetchFollowersSuccess,
      meta: followersData?.pages[followersData?.pages?.length - 1]?.data.meta,
      fetchNextPage: fetchNextFollowersPage,
      isLoading: fetchFollowersLoading || isFetchingFollowersNextPage,
      noResultsMessage: t('NoFollowers', { sellerUsername: shopUsername }),
    },
    {
      id: FollowRole.Following,
      value: 1,
      label: t('Following', { count: 2 }),
      hasError: fetchFollowingError,
      data: followings,
      isSuccess: fetchFollowingSuccess,
      meta: followingData?.pages[followingData?.pages?.length - 1]?.data.meta,
      fetchNextPage: fetchNextFollowingPage,
      isLoading: fetchFollowingLoading || isFetchingFollowingNextPage,
      noResultsMessage: t('NoFollowing', { sellerUsername: shopUsername }),
    },
  ];

  function switchTabs(role: FollowRole, tab: number) {
    setActiveTab(tab);
  }

  return (
    <>
      <PrimaryTabs
        tabs={followsTabsDetails}
        activeValue={activeTab}
        onChange={(e) =>
          switchTabs(e.target.id as FollowRole, Number(e.target.value))
        }
        name="follows-tabs"
      />
      <TabPanels activeIndex={activeTab}>
        {followsTabsDetails.map(
          ({
            id,
            hasError,
            isSuccess,
            data,
            fetchNextPage,
            meta,
            isLoading,
            noResultsMessage,
          }) => {
            const hasData = data && data.length > 0;
            const showItems = isLoading || hasData;
            return (
              <TabPanel key={id} id={id} className={styles.container}>
                {isSuccess && !data?.length && (
                  <div className={styles['wrapper--noResults']}>
                    <Text>{noResultsMessage}</Text>
                  </div>
                )}
                {hasError && (
                  <div className={styles['wrapper--noResults']}>
                    <ErrorMessage type="body">
                      {tCommon('500.Message')}
                    </ErrorMessage>
                  </div>
                )}
                {showItems && (
                  <div className={styles.items}>
                    <InfiniteScroll
                      onEndReached={fetchNextPage}
                      hasMore={!meta?.end}
                      LoadingComponent={<LoadingBall />}
                      loading={isLoading}
                    >
                      {data?.map((userRow) => (
                        <FollowsModalRow
                          key={userRow.id}
                          {...userRow}
                          isOwnShop={isOwnShop}
                          shopUsername={shopUsername}
                        />
                      ))}
                    </InfiniteScroll>
                  </div>
                )}
              </TabPanel>
            );
          }
        )}
      </TabPanels>
    </>
  );
}

export { FollowsModal };
