import React from 'react';
import { AVATAR_SIZE, Avatar } from '@depop/web-ui-kit/Avatar';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import { FollowsButton } from '../../FollowsButton';

import styles from './styles.module.css';

import { ExtendedLink } from '@/components/ExtendedLink';
import { InternalFollowsItem } from '@/modules/follows/types';
import { getNameVariations } from '@/modules/shop/helpers/getNameVariations';
import { useFollows } from '@/modules/follows/useFollows';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import {
  RQ_FOLLOWERS,
  RQ_FOLLOWING,
  RQ_FOLLOWING_RELATIONSHIP,
  RQ_SHOP_USERNAME_KEY,
} from '@/modules/ReactQuery/cacheKeys';

type Props = InternalFollowsItem & { isOwnShop: boolean; shopUsername: string };

function FollowsModalRow({
  isOwnShop,
  username,
  shopUsername,
  id,
  ...props
}: Props) {
  const [, currentUser] = useCurrentUser();
  const { fullName } = getNameVariations(props.first_name, props.last_name);
  const isMine = id === currentUser?.id;
  const { initials } = getNameVariations(props.first_name, props.last_name);

  const { isUpdatingFollowCount, handleFollowsButtonClick } = useFollows({
    isFollowing: props.following,
    queriesToInvalidate: isOwnShop
      ? [
          [RQ_FOLLOWING],
          [RQ_FOLLOWERS],
          [RQ_FOLLOWING_RELATIONSHIP, shopUsername],
          [RQ_SHOP_USERNAME_KEY, shopUsername],
        ]
      : [[RQ_FOLLOWING], [RQ_FOLLOWERS]],
  });

  return (
    <div className={styles.container}>
      <ExtendedLink
        href="/[username]"
        as={`/${username}/`}
        className={styles.userDetails}
      >
        <Avatar
          src={
            props.picture_data?.formats?.U3 && props.picture_data.formats.U3.url
          }
          verified={!!props.badge}
          initials={initials}
          size={AVATAR_SIZE.MEDIUM}
        />
        <div>
          <Text bold>{fullName}</Text>
          <Text type="caption1">{`@${username}`}</Text>
        </div>
      </ExtendedLink>
      {!isMine && (
        <FollowsButton
          isFollowing={props.following}
          isUpdatingFollowCount={isUpdatingFollowCount}
          onButtonClick={() => handleFollowsButtonClick(id)}
        />
      )}
    </div>
  );
}

export { FollowsModalRow };
