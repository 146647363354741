import React from 'react';
import { usePathname } from 'next/navigation';

import styles from './styles.module.css';
import { SignUpModalAction } from './DownloadAppModal';

import { useUserAgent } from '@/modules/device/useUserAgent';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { getRouteName } from '@/modules/activityTracker/referers';
import { DeviceType } from '@/modules/device/types';
import { DownloadAppButton } from '@/components/DownloadAppButton';
import { DownloadAppQRCode } from '@/components/DownloadAppQRCode';

export function AppDownloadSection() {
  const [isAuthenticated, currentUser] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });
  const { device } = useUserAgent();
  const pathname = usePathname();

  const isAndroid = device === DeviceType.ANDROID;

  function sendSignupModalActionEvent(actionType: SignUpModalAction) {
    const pageType = getRouteName(pathname);
    sendActivityTrackerEvent(ActivityTrackerEventType.SIGNUP_MODAL_ACTION, {
      pageType,
      actionType,
      schemaVersion: '1.0',
    });
  }

  return (
    <div className={styles.appDownloadContainer}>
      <div className={styles.downloadButtonContainer}>
        <DownloadAppButton
          outline={!isAuthenticated}
          onClick={() =>
            sendSignupModalActionEvent(
              isAndroid
                ? SignUpModalAction.PLAY_STORE
                : SignUpModalAction.APP_STORE
            )
          }
        />
      </div>
      <DownloadAppQRCode />
    </div>
  );
}
