'use client';

import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { useQuery } from '@tanstack/react-query';
import { Modal } from '@depop/web-ui-kit/Modal';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import { FollowsButton } from '../FollowsButton';
import { FollowsModal } from '../FollowsModal';

import styles from './styles.module.css';

import { formatNumberWithMetricPrefix } from '@/modules/text/helpers';
import { fetchFollowingRelationship } from '@/modules/follows/api';
import {
  RQ_FOLLOWING_RELATIONSHIP,
  RQ_SHOP_USERNAME_KEY,
} from '@/modules/ReactQuery/cacheKeys';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { DownloadAppModal } from '@/components/DownloadAppModal/DownloadAppModal';
import { useFollows } from '@/modules/follows/useFollows';
import { FollowRole } from '@/modules/follows/types';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { useIsViewingOwnShop } from '@/modules/shop/hooks/useIsViewingOwnShop';

type Props = {
  following: number;
  followers: number;
  username: string;
  sellerId: number;
};

export function Follows({ sellerId, following, followers, username }: Props) {
  const [isAuthenticated, currentUser] = useCurrentUser();
  const userId = currentUser?.id;
  const t = useTranslations('shop');
  const [isModalOpen, setIsModalOpen] = useState<FollowRole | boolean>(false);
  const modalTrigger = useRef(null);
  const modalTab = isModalOpen === FollowRole.Followers ? 0 : 1;
  const isOwnShop = useIsViewingOwnShop();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId,
  });

  const { data: followingRelationshipData } = useQuery({
    queryKey: [RQ_FOLLOWING_RELATIONSHIP, username],
    queryFn: () => fetchFollowingRelationship(sellerId),
    enabled: isAuthenticated,
  });

  const { is_following } = followingRelationshipData?.data || {};
  const { isUpdatingFollowCount, handleFollowsButtonClick } = useFollows({
    isFollowing: !!is_following,
    queriesToInvalidate: [
      [RQ_FOLLOWING_RELATIONSHIP, username],
      [RQ_SHOP_USERNAME_KEY, username],
    ],
  });

  const followButtons = [
    {
      followRole: FollowRole.Followers,
      value: formatNumberWithMetricPrefix(followers),
      description: t('Followers', { count: followers }),
    },
    {
      followRole: FollowRole.Following,
      value: formatNumberWithMetricPrefix(Math.max(0, following)),
      description: t('Following', { count: Math.max(0, following) }),
    },
  ];

  async function handleButtonClick() {
    if (!isAuthenticated) {
      setIsModalOpen(true);
      sendActivityTrackerEvent(
        ActivityTrackerEventType.USER_INTERACTION_ACTION,
        { interactionType: 'FollowIntent', userId: sellerId }
      );
    } else {
      await handleFollowsButtonClick(sellerId);
    }
  }

  function handleFollowCountClick(followRole: FollowRole) {
    setIsModalOpen(followRole);
  }

  return (
    <div className={styles.container}>
      {followButtons.map(({ followRole, description, value }) => (
        <button
          key={followRole}
          className={clsx(styles.followCount, {
            [styles.followCountOwnShop]: isOwnShop,
          })}
          aria-label={
            followRole === FollowRole.Followers
              ? t('ShowFollowers')
              : t('ShowFollowing')
          }
          aria-haspopup="dialog"
          onClick={() => handleFollowCountClick(followRole)}
          ref={modalTrigger}
        >
          <Text as="span" bold>
            {value}
          </Text>{' '}
          <Text as="span" className={styles.followRole}>
            {description}
          </Text>
        </button>
      ))}
      {!isOwnShop && (
        <FollowsButton
          isFollowing={is_following}
          onButtonClick={handleButtonClick}
          isUpdatingFollowCount={isUpdatingFollowCount}
        />
      )}
      <Modal
        isOpen={!!isModalOpen}
        setOpen={setIsModalOpen}
        title={isAuthenticated ? `@${username}` : ''}
        triggerRef={modalTrigger}
        content={() =>
          isAuthenticated ? (
            <FollowsModal
              sellerId={sellerId}
              isOwnShop={isOwnShop}
              shopUsername={username}
              defaultTab={modalTab}
            />
          ) : (
            <DownloadAppModal />
          )
        }
        wide={isAuthenticated}
      />
    </div>
  );
}
