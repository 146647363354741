export interface Conversation {
  conversationId: string;
  userId: number;
  lastMessageText?: string;
  unreadCount: number;
  lastReadTimestamp: number;
  lastMessageTimestamp: number;
  unreadTimestamp?: number;
  users: ConversationUser[];
  product?: ConversationProduct;
  meta: ConversationMeta;
  readOnly: boolean;
  chatMetaStatus?: ChatMetaStatus;
}

export interface FetchConversationByIdResponse {
  conversationId: string;
  user: ConversationUser;
  product?: ConversationProduct;
  readOnly: boolean;
  chatMetaStatus?: ChatMetaStatus;
}

export type FetchConversationByUserIdResponse = {
  conversationId: string;
  user: ConversationUser;
  product?: ConversationProduct;
};

export interface ConversationMeta {
  cursor: string | null;
}

export interface ConversationUser {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  pictureUrl?: string;
  badge?: string;
}

export interface ConversationProduct {
  id: number;
  userId: number;
  pictureUrl: string;
}

export interface Meta {
  cursor: string | null;
  end: boolean;
}

export interface PaginatedConversationsResponse {
  meta: Meta;
  conversations: Conversation[];
}

export type Message = {
  id: string;
  userId: number;
  text: string;
  createdTimestamp: number;
};

export interface PaginatedMessagesResponse {
  meta: Meta;
  messages: Message[];
}

export type SendMessageRequest = {
  userId: number;
  text: string;
  productId?: number;
};

export type SendMessageResponse = Message & {
  conversationId: string;
};

export type MessageCachePartialType = {
  pages: [{ data: { messages: Message[] } }];
};

export enum ChatMetaStatus {
  UserBanned = 'USER_BANNED',
  SellerAndProductBanned = 'SELLER_AND_PRODUCT_BANNED',
  BuyerAndProductBanned = 'BUYER_AND_PRODUCT_BANNED',
  BuyerBannedAndProductDeleted = 'BUYER_BANNED_AND_PRODUCT_DELETED',
  ProductBanned = 'PRODUCT_BANNED',
  ProductDeleted = 'PRODUCT_DELETED',
  BuyerBanned = 'BUYER_BANNED',
}
