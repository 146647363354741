import { useEffect } from 'react';
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { DragEndEvent } from '@dnd-kit/core';

import { RearrangeProductsRequest, ShopProductsResponse } from '../types';

import {
  RQ_FILTERED_SHOP_PRODUCTS,
  RQ_SHOP_PRODUCTS,
} from '@/modules/ReactQuery/cacheKeys.ts';
import { rearrangeProducts } from '@/modules/shop/api.ts';
import { useActiveShopTab } from '@/modules/shop/hooks/useActiveShopTab';
import { useIsViewingOwnShop } from '@/modules/shop/hooks/useIsViewingOwnShop';
import {
  ActivityTrackerEventType,
  ShopProfileSection,
  ShopRearrangeMethods,
} from '@/modules/activityTracker/constants.ts';
import {
  setIsRearrangeModeActive,
  addRearrangedProductChangeSet,
  setRearrangeableProductList,
} from '@/modules/redux/slices/shopSlice';
import { RootState } from '@/modules/redux/stores/storeClient';
import { ListProduct } from '@/modules/product/types';
import { canSort, sortListWithIndices } from '@/modules/dragAndDrop/helpers';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { AxiosCompatibleResponse } from '@/modules/http/types';
import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';

export function useRearrangeProducts(
  sellerId?: number,
  products?: ListProduct[]
) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { tab } = useActiveShopTab();
  const isViewingOwnShop = useIsViewingOwnShop();
  const {
    rearrangeableProductList,
    isRearrangeModeActive,
    rearrangedProductsChangeSet,
  } = useSelector((state: RootState) => state.shop);
  const { sendActivityTrackerEvent } = useActivityTracker({ userId: sellerId });

  const { web_6224_sold_items_bottom_of_the_shop } = useExperiments([
    'web_6224_sold_items_bottom_of_the_shop',
  ]);
  const isSoldItemsOnTheBottomOfShop =
    web_6224_sold_items_bottom_of_the_shop === VARIANT_IDENTIFIER;

  function getHasShopProducts() {
    const shopProductsQuery = queryClient.getQueryData<
      InfiniteData<ShopProductsResponse>
    >([RQ_SHOP_PRODUCTS, sellerId]);
    const shopProducts = shopProductsQuery?.pages?.[0]?.products || [];
    const hasShopProducts = shopProducts.length > 0;

    if (!isSoldItemsOnTheBottomOfShop) {
      return hasShopProducts;
    }

    const filteredProductsQuery = queryClient.getQueryData<
      InfiniteData<AxiosCompatibleResponse<ShopProductsResponse>>
    >([RQ_FILTERED_SHOP_PRODUCTS, { sellerId }]);

    const filteredProducts =
      filteredProductsQuery?.pages[0]?.data.products || [];
    const hasFilteredProducts = filteredProducts.length > 0;

    return hasShopProducts || hasFilteredProducts;
  }

  function handleExitRearrangeMode(isCancel?: boolean) {
    dispatch(
      setIsRearrangeModeActive({
        isActive: false,
        isCancel,
      })
    );
  }

  const rearrangeProductsMutation = useMutation({
    mutationFn: (data: RearrangeProductsRequest) =>
      rearrangeProducts(sellerId as number, data),
    onSuccess: async (_, requestPayload: RearrangeProductsRequest) => {
      sendActivityTrackerEvent(ActivityTrackerEventType.SHOP_REARRANGE_ACTION, {
        method: requestPayload?.move_sold_to_end
          ? ShopRearrangeMethods.MoveSold
          : ShopRearrangeMethods.Rearrange,
        schemaVersion: '1.0',
      });

      await queryClient.invalidateQueries({
        queryKey: isSoldItemsOnTheBottomOfShop
          ? [RQ_FILTERED_SHOP_PRODUCTS, { sellerId }]
          : [RQ_SHOP_PRODUCTS, sellerId],
      });
      handleExitRearrangeMode();
      rearrangeProductsMutation.reset();
    },
  });

  useEffect(() => {
    if (products?.length) {
      dispatch(
        setRearrangeableProductList({
          isInitial: true,
          productList: products,
        })
      );
    }
  }, [products?.length]);

  const isRearrangeModeSupported =
    tab === ShopProfileSection.Shop && isViewingOwnShop && getHasShopProducts();

  function handleRearrangeDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    const canRearrangeProducts = canSort(active, over);

    if (canRearrangeProducts) {
      const oldIndex = rearrangeableProductList.findIndex(
        (product) => product.id === active?.id
      );
      const newIndex = rearrangeableProductList.findIndex(
        (product) => product.id === over?.id
      );
      const updatedList = sortListWithIndices(
        rearrangeableProductList,
        oldIndex,
        newIndex
      );

      dispatch(
        setRearrangeableProductList({
          productList: updatedList,
        })
      );

      dispatch(
        addRearrangedProductChangeSet({
          from: oldIndex,
          to: newIndex,
          how_many: 1,
        })
      );
    }
  }

  function handleRearrangeInitialiseClick() {
    dispatch(
      setIsRearrangeModeActive({
        isActive: true,
      })
    );
  }

  function handleMoveSoldClick() {
    rearrangeProductsMutation.mutate({
      move_sold_to_end: true,
      changeset: [],
    });
  }

  function handleSaveRearrangeClick() {
    rearrangeProductsMutation.mutate({
      move_sold_to_end: false,
      changeset: rearrangedProductsChangeSet,
    });
  }

  return {
    isRearrangeModeActive,
    isRearrangeModeSupported,
    handleRearrangeDragEnd,
    rearrangeableProductList,
    setRearrangeableProductList,
    handleRearrangeInitialiseClick,
    handleSaveRearrangeClick,
    handleCancelRearrangeClick: handleExitRearrangeMode,
    handleMoveSoldClick,
    isRearrangedListSaving: rearrangeProductsMutation.isPending,
    isRearrangedListSaved: rearrangeProductsMutation.isSuccess,
  };
}
