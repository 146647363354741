import { usePathname } from 'next/navigation';

import { ShopProfileSection } from '@/modules/activityTracker/constants';

export function useActiveShopTab() {
  const pathname = usePathname();

  if (pathname.match(/\/[_a-z0-9]+\/saved/)) {
    return { tab: ShopProfileSection.Saved };
  }
  if (pathname.match(/\/[_a-z0-9]+\/selling/)) {
    return {
      tab: ShopProfileSection.Selling,
    };
  }
  if (pathname.match(/\/[_a-z0-9]+\/sold/)) {
    return { tab: ShopProfileSection.Sold };
  }
  if (pathname.match(/\/[_a-z0-9]+\/likes/)) {
    return { tab: ShopProfileSection.Likes };
  }
  return { tab: ShopProfileSection.Shop };
}
