import http from '@/modules/http';
import { AxiosCompatibleResponse } from '@/modules/http/types.ts';
import {
  FOLLOWING_RELATIONSHIP_ENDPOINT,
  DELETE_FOLLOWING_ENDPOINT,
  FOLLOWERS_ENDPOINT,
  FOLLOWING_ENDPOINT,
} from '@/constants/endpoints.ts';
import {
  FollowingRelationshipResponse,
  FollowRole,
  InternalFollowsResponse,
} from '@/modules/follows/types.ts';

// The internal followsapi defaults to 100 and this is also the minimum, see apiary doc
export const FOLLOWS_LIMIT = 100;

export function fetchFollowingRelationship(
  userId: number
): Promise<AxiosCompatibleResponse<FollowingRelationshipResponse>> {
  return http.get(
    FOLLOWING_RELATIONSHIP_ENDPOINT.replace(':userId', String(userId)),
    {
      withAuth: true,
    }
  );
}

export function addFollowing({
  userId,
  sellerId,
}: {
  userId: number;
  sellerId: number;
}): Promise<AxiosCompatibleResponse<void>> {
  return http.put(
    FOLLOWING_ENDPOINT.replace(':sellerId', String(userId)),
    {},
    {
      withAuth: true,
      params: {
        user_id: sellerId,
      },
    }
  );
}

export function deleteFollowing({
  userId,
  sellerId,
}: {
  userId: number;
  sellerId: number;
}): Promise<AxiosCompatibleResponse<void>> {
  return http.delete(
    DELETE_FOLLOWING_ENDPOINT.replace(':userId', String(userId)).replace(
      ':sellerId',
      String(sellerId)
    ),
    { withAuth: true }
  );
}

export function fetchFollows({
  role,
  sellerId,
  offset,
}: {
  role: FollowRole;
  sellerId: number;
  offset?: string;
}): Promise<AxiosCompatibleResponse<InternalFollowsResponse>> {
  const endpoint =
    role === 'followers'
      ? FOLLOWERS_ENDPOINT.replace(':sellerId', sellerId.toString())
      : FOLLOWING_ENDPOINT.replace(':sellerId', sellerId.toString());

  return http.get(endpoint, {
    params: {
      offset,
      limit: FOLLOWS_LIMIT,
    },
    withAuth: true,
  });
}
