import React from 'react';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import { ExtendedLink } from '@/components/ExtendedLink';

type Props = {
  isActive: boolean;
  href: string;
  children: string;
};

export function NavigationTab({ isActive, href, children }: Props) {
  return isActive ? (
    <Text bold>{children}</Text>
  ) : (
    <ExtendedLink href={href} rel="nofollow">
      <Text>{children}</Text>
    </ExtendedLink>
  );
}
