export enum FollowRole {
  Followers = 'followers',
  Following = 'following',
}

export interface InternalFollowsItem {
  id: number;
  username: string;
  badge?: 'verified' | 'staff';
  first_name: string;
  following: boolean;
  last_name: string;
  following_count: number;
  followers_count: number;
  picture_data?: {
    formats: {
      [key: string]: {
        height: number;
        width: number;
        url: string;
      };
    };
  };
  country: string;
  language: string;
}

export interface InternalFollowsMeta {
  end: boolean;
  limit?: number;
  offset?: number;
}

export type InternalFollowsResponse = {
  meta: InternalFollowsMeta;
  objects: InternalFollowsItem[];
};

export type FollowsMeta = InternalFollowsMeta;

export type FollowingRelationshipResponse = {
  is_followed_by: boolean;
  is_following: boolean;
};
