'use client';

import React from 'react';
import { Avatar } from '@depop/web-ui-kit/Avatar';

import { ExtendedLink } from '@/components/ExtendedLink';

type Props = {
  href: string;
  ariaLabel?: string;
} & React.ComponentProps<typeof Avatar>;

export function AvatarWithLink({ href, ariaLabel, ...rest }: Props) {
  return (
    <ExtendedLink href={href} aria-label={ariaLabel}>
      <Avatar {...rest} />
    </ExtendedLink>
  );
}
