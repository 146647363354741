'use client';

import React from 'react';
import { Button } from '@depop/web-ui-kit/Button';
import { useTranslations } from 'next-intl';

import { ExtendedLink } from '@/components/ExtendedLink';
import { DeviceType } from '@/modules/device/types';
import { fallbackLinks } from '@/modules/appStoreLinks/constants';
import { useBranchDeepLink } from '@/modules/externalTracking/useBranchDeepLink';
import { useCurrentLanguage } from '@/modules/language/useCurrentLanguage';
import { useUserAgent } from '@/modules/device/useUserAgent';

type Props = React.ComponentProps<typeof Button> & {
  label?: string;
};

export function DownloadAppButton({ label, ...rest }: Props) {
  const t = useTranslations('common');
  const { language } = useCurrentLanguage();
  const { device } = useUserAgent();
  const isAndroid = device === DeviceType.ANDROID;
  const fallbackLink =
    fallbackLinks[language][isAndroid ? 'PlayStore' : 'AppStore'];
  const appStoreLink = useBranchDeepLink({
    fallback: fallbackLink,
    appPath: '/',
    referer: '',
    stage: 'download app link',
    feature: 'organic',
    channel: 'website',
    campaign: '',
  });

  return (
    <ExtendedLink href={appStoreLink} passHref legacyBehavior>
      <Button {...rest} as="a">
        {label || t('DownloadTheApp')}
      </Button>
    </ExtendedLink>
  );
}
