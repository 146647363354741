import React, { useEffect } from 'react';
import { useTranslations } from 'next-intl';
import { H2 } from '@depop/web-ui-kit/Typography/H2';
import { Button } from '@depop/web-ui-kit/Button';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { usePathname, useSearchParams } from 'next/navigation';

import { ExtendedLink } from '../ExtendedLink';

import styles from './styles.module.css';
import { AppDownloadSection } from './AppDownloadSection';

import { useSignup } from '@/modules/signup/hooks/useSignup';
import { getLoginRedirectParams } from '@/modules/login/helpers';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { getRouteName } from '@/modules/activityTracker/referers';

const CLOUDFRONT_HOST = process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST;

export enum SignUpModalAction {
  APP_STORE = 'AppStore',
  LOGIN = 'Login',
  PLAY_STORE = 'PlayStore',
  SIGN_UP = 'Signup',
}

export type Props = {
  followedUser?: string;
};

export function DownloadAppModal({ followedUser }: Props) {
  const t = useTranslations('common');
  const { url: signupUrl } = useSignup();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const queryString = searchParams.toString();
  const pathnameWithQuery = `${pathname}${
    queryString ? `?${queryString}` : ''
  }`;
  const pageType = getRouteName(pathname);
  const [isAuthenticated, currentUser] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });

  useEffect(() => {
    sendActivityTrackerEvent(ActivityTrackerEventType.SIGNUP_MODAL_VIEW, {
      pageType,
      schemaVersion: '1.0',
    });
  }, []);

  function sendSignupModalActionEvent(actionType: SignUpModalAction) {
    sendActivityTrackerEvent(ActivityTrackerEventType.SIGNUP_MODAL_ACTION, {
      pageType,
      actionType,
      schemaVersion: '1.0',
    });
  }

  return (
    <div className={styles.modalContainer}>
      <img
        src={`${CLOUDFRONT_HOST}/web/assets/images/depop-smile-sticker.png`}
        srcSet={`${CLOUDFRONT_HOST}/web/assets/images/depop-smile-sticker.png 251w, ${CLOUDFRONT_HOST}/web/assets/images/depop-smile-sticker@2x.png 501w`}
        alt="Depop"
        className={styles.depopSticker}
      />
      <H2 className={styles.modalTitle}>{t('LogInModal.LoggedOutTitle')}</H2>
      {!isAuthenticated && (
        <>
          <Text className={styles.modalBodyText}>
            {followedUser
              ? t('LogInModal.FollowBody', { user: followedUser })
              : t('LogInModal.LoggedOutBody')}
          </Text>
          <ExtendedLink href={signupUrl} passHref legacyBehavior>
            <Button
              className={styles.signupButton}
              as="a"
              onClick={() =>
                sendSignupModalActionEvent(SignUpModalAction.SIGN_UP)
              }
              block
            >
              {t('Nav.SignUp')}
            </Button>
          </ExtendedLink>
          <ExtendedLink
            href={`/login/${getLoginRedirectParams(pathnameWithQuery)}`}
            passHref
            legacyBehavior
          >
            <Button
              className={styles.loginButton}
              as="a"
              onClick={() =>
                sendSignupModalActionEvent(SignUpModalAction.LOGIN)
              }
              block
              outline
            >
              {t('LogInModal.LogIn')}
            </Button>
          </ExtendedLink>
          <div className={styles.horizontalBreakContainer}>
            <hr className={styles.horizontalBreak} />
            <Text className={styles.orText}>{t('LogInModal.Or')}</Text>
            <hr className={styles.horizontalBreak} />
          </div>
        </>
      )}
      <AppDownloadSection />
    </div>
  );
}
