import React from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

type Props = {
  size?: 'sm' | 'md';
};

export function DownloadAppQRCode({ size = 'sm' }: Props) {
  const t = useTranslations('common');
  const isMedium = size === 'md';

  return (
    <div className={styles.container}>
      <img
        src={`${process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST}/web/assets/get-the-app-qr-code.svg`}
        alt={t('GetTheApp.QRCode.Alt')}
        className={clsx({
          [styles.qrCode]: true,
          [styles['qrCode--md']]: isMedium,
        })}
      />
      <Text
        bold
        className={clsx({
          [styles.qrCodeTitle]: true,
          [styles['qrCodeTitle--md']]: isMedium,
        })}
      >
        {t('DownloadApp.QRTitle')}
      </Text>
      <Text
        className={clsx({
          [styles.qrCodeText]: true,
          [styles['qrCodeText--md']]: isMedium,
        })}
      >
        {t('DownloadApp.QRBody')}
      </Text>
    </div>
  );
}
