import { useContext } from 'react';
import { useSearchParams } from 'next/navigation';

import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import {
  ActivityTrackerEventType,
  sessionLandingPageKey,
} from '@/modules/activityTracker/constants';
import {
  ProductBySlugExtendedResponse,
  ProductBySlugResponse,
} from '@/modules/product/types';
import { TransformedPriceEstimateDictionary } from '@/modules/price/types.ts';
import { getLocalStorageItem } from '@/modules/storage';
import { useExternalTracking } from '@/modules/externalTracking/useExternalTracking';
import { segmentEventTypes } from '@/modules/externalTracking/types';
import { RefererContext } from '@/modules/activityTracker/RefererProvider';
import { getSegmentProductData } from '@/modules/product/helpers/getSegmentProductData';
import { getProductViewTransitionFrom } from '@/modules/product/helpers/getProductViewTransitionFrom';
import { getReferer } from '@/modules/activityTracker/referers';

export function useProductTracking() {
  const searchParams = useSearchParams();
  const referer = useContext(RefererContext) || null;
  const [isAuthenticated, user] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({ userId: user?.id });
  const {
    segment: { sendEvent: sendSegmentEvent },
    ga: { sendEvent: sendGAEvent },
  } = useExternalTracking();

  const isBoostedView = Boolean(
    isAuthenticated && searchParams.get('isBoostedView') === 'true'
  );

  const transitionFrom = getProductViewTransitionFrom(
    searchParams.get('from') || getReferer(referer),
    isBoostedView
  );

  function sendProductViewGAEvent(
    productId: number,
    productPricing: TransformedPriceEstimateDictionary,
    variantSetId?: number
  ) {
    sendGAEvent({
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_id: variantSetId
              ? `${productId}-${variantSetId}`
              : productId.toString(),
            price: parseFloat(
              productPricing.original_price.price_breakdown.price.amount
            ),
          },
        ],
        currency: productPricing.currency_name,
        value: parseFloat(
          productPricing.original_price.price_breakdown.price.amount
        ),
      },
    });
  }

  function sendProductViewATEvent(
    productId: number,
    productPricing: TransformedPriceEstimateDictionary
  ) {
    sendActivityTrackerEvent(
      ActivityTrackerEventType.PRODUCT_VIEW,
      {
        schemaVersion: '4.0',
        productId,
        originalPrice: Number(
          productPricing.original_price.price_breakdown.price.amount
        ),
        discountedPrice:
          productPricing.discounted_price?.price_breakdown.price.amount &&
          Number(productPricing.discounted_price.price_breakdown.price.amount),
        taxInclusiveOriginalPrice:
          productPricing.original_price.total_price &&
          Number(productPricing.original_price.total_price),
        taxInclusiveDiscountedPrice:
          productPricing.discounted_price?.total_price &&
          Number(productPricing.discounted_price.total_price),
        currency: productPricing.currency_name,
      },
      {
        baseEventOverrides: {
          transitionFrom,
        },
      }
    );
  }

  function sendEngagedSessionActionATEvent() {
    const landingPage = getLocalStorageItem(sessionLandingPageKey);
    sendActivityTrackerEvent(
      ActivityTrackerEventType.ENGAGED_SESSION_ACTION,
      {
        landingPage,
        schemaVersion: '1.0',
      },
      {
        baseEventOverrides: {
          transitionFrom,
        },
      }
    );
  }

  function sendWebModuleAttributionProductViewATEvent(
    productId: number,
    moduleOrigin: string
  ) {
    sendActivityTrackerEvent(
      ActivityTrackerEventType.WEB_MODULE_ATTRIBUTION_PRODUCT_VIEW,
      {
        productId,
        moduleName: moduleOrigin,
        schemaVersion: '1.0',
      },
      {
        baseEventOverrides: {
          transitionFrom,
        },
      }
    );
  }

  function sendProductViewedSegmentedEvent(
    product: ProductBySlugResponse,
    productExtended: ProductBySlugExtendedResponse
  ) {
    const segmentProductData = getSegmentProductData(product, productExtended);

    if (productExtended.sizes) {
      productExtended.sizes.forEach((size) => {
        sendSegmentEvent(segmentEventTypes.PRODUCT_VIEWED, {
          ...segmentProductData,
          sku: size.id ? product.id + '-' + size.id : product.id.toString(),
        });
      });
    } else {
      sendSegmentEvent(segmentEventTypes.PRODUCT_VIEWED, segmentProductData);
    }
  }

  function sendProductInteractionActionATEvent(
    productId: number | undefined,
    interactionType: string,
    optionalParams?: {
      hashTag?: string;
      isBoosted?: boolean;
      brand?: string;
    }
  ) {
    sendActivityTrackerEvent(
      ActivityTrackerEventType.PRODUCT_INTERACTION_ACTION,
      {
        schemaVersion: '4.0',
        productId,
        interactionType,
        ...optionalParams,
      }
    );
  }

  function sendBuyNowActionEvents(
    product: ProductBySlugResponse,
    productExtended: ProductBySlugExtendedResponse,
    callback?: () => void
  ) {
    const status = isAuthenticated ? 'Authenticated' : 'Guest';

    sendGAEvent({
      category: 'Product',
      action: 'buy product',
      label: status,
    });

    const segmentProductData = getSegmentProductData(product, productExtended);

    sendSegmentEvent(segmentEventTypes.PRODUCT_ADDED, {
      ...segmentProductData,
      email: user?.email || '',
    });

    sendActivityTrackerEvent(ActivityTrackerEventType.BUY_NOW_ACTION, {
      productId: product.id,
      sellerId: product.seller.id,
    }).then(callback);
  }

  function sendBuyerProtectionLinkAction({
    productId,
    sellerId,
  }: {
    productId: number;
    sellerId: number;
  }) {
    sendActivityTrackerEvent(
      ActivityTrackerEventType.BUYER_PROTECTION_LINK_ACTION,
      {
        productId,
        sellerId,
        schemaVersion: '1.0',
      }
    );
  }

  return {
    sendProductViewATEvent,
    sendProductViewGAEvent,
    sendProductViewedSegmentedEvent,
    sendEngagedSessionActionATEvent,
    sendProductInteractionActionATEvent,
    sendWebModuleAttributionProductViewATEvent,
    sendBuyNowActionEvents,
    sendBuyerProtectionLinkAction,
  };
}
