import { DEPOP_SUPPORT_USER_IDS } from '../constants';
import { ChatMetaStatus } from '../types';

export function buildMessagesCreateUrl({
  recipientId = '',
  productId = '',
}: {
  recipientId?: number | string;
  productId?: number | string | null;
}) {
  const query = new URLSearchParams({
    userId: String(recipientId),
    ...(productId && { productId: String(productId) }),
  });

  return `/messages/create?${query}`;
}

export function isProductBanned(status?: ChatMetaStatus) {
  if (!status) {
    return false;
  }

  return [
    ChatMetaStatus.SellerAndProductBanned,
    ChatMetaStatus.BuyerAndProductBanned,
    ChatMetaStatus.ProductBanned,
  ].includes(status);
}

export function isProductDeleted(status?: ChatMetaStatus) {
  if (!status) {
    return false;
  }

  return [
    ChatMetaStatus.BuyerBannedAndProductDeleted,
    ChatMetaStatus.ProductDeleted,
  ].includes(status);
}

export function getProductWarningMessage(
  chatMetaStatus: ChatMetaStatus,
  hasInactiveUserWithProduct: boolean
) {
  if (
    hasInactiveUserWithProduct ||
    chatMetaStatus === ChatMetaStatus.SellerAndProductBanned
  ) {
    return 'ChatStatus.SellerBannedAndProductBanned';
  }
  if (chatMetaStatus === ChatMetaStatus.ProductBanned) {
    return 'ChatStatus.BannedItem';
  }

  if (isProductDeleted(chatMetaStatus)) {
    return 'ChatStatus.DeletedProduct';
  }
}

export function getReadOnlyMessage(chatMetaStatus?: ChatMetaStatus) {
  if (
    [
      ChatMetaStatus.BuyerAndProductBanned,
      ChatMetaStatus.BuyerBanned,
      ChatMetaStatus.UserBanned,
      ChatMetaStatus.BuyerBannedAndProductDeleted,
    ].includes(chatMetaStatus as ChatMetaStatus)
  ) {
    return 'ChatStatus.InactiveUser';
  }

  return 'ChatStatus.ReadOnlyMessage';
}

export function isDepopSupport(id?: number | string) {
  return DEPOP_SUPPORT_USER_IDS.includes(String(id));
}

export function getDomesticShippingText(
  isOwnProduct: boolean,
  hasDepopShipping: boolean
) {
  if (isOwnProduct) {
    if (hasDepopShipping) {
      return 'ItemSummary.DepopShipping';
    }
    return 'ItemSummary.ManualShipping';
  }
  return false;
}
