import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addFollowing, deleteFollowing } from './api';

import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker.ts';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { AxiosCompatibleResponse } from '@/modules/http/types';

type Props = {
  isFollowing: boolean;
  queriesToInvalidate: string[][];
};

function useFollows({ isFollowing, queriesToInvalidate }: Props) {
  const queryClient = useQueryClient();
  const [_, currentUser] = useCurrentUser();
  const userId = currentUser?.id;
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId,
  });

  function handleFollowActionSuccess(
    _: AxiosCompatibleResponse<void>,
    {
      sellerId,
    }: {
      userId: number;
      sellerId: number;
    }
  ) {
    sendActivityTrackerEvent(ActivityTrackerEventType.USER_INTERACTION_ACTION, {
      interactionType: isFollowing ? 'Unfollow' : 'Follow',
      userId: sellerId,
    });

    queriesToInvalidate.forEach((queryKey) => {
      queryClient.invalidateQueries({
        queryKey,
      });
    });
  }

  const deleteFollowingMutation = useMutation({
    mutationFn: deleteFollowing,
    onSuccess: handleFollowActionSuccess,
  });
  const addFollowMutation = useMutation({
    mutationFn: addFollowing,
    onSuccess: handleFollowActionSuccess,
  });

  const isUpdatingFollowCount =
    deleteFollowingMutation.isPending || addFollowMutation.isPending;

  async function handleFollowsButtonClick(sellerId: number) {
    if (!currentUser?.id) {
      return;
    }

    if (isFollowing) {
      deleteFollowingMutation.mutate({ sellerId, userId: currentUser.id });
    } else {
      addFollowMutation.mutate({ sellerId, userId: currentUser?.id });
    }
  }

  return {
    isUpdatingFollowCount,
    handleFollowsButtonClick,
  };
}

export { useFollows };
