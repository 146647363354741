import {
  ProductBySlugExtendedResponse,
  ProductBySlugResponse,
} from '@/modules/product/types';
import { stringToPrice } from '@/modules/price/helpers/stringToPrice';

export function getSegmentProductData(
  product: ProductBySlugResponse,
  productExtended: ProductBySlugExtendedResponse
) {
  return {
    product_id: product.id.toString(),
    sku: productExtended.sizes
      ? product.id + '-' + productExtended.sizes[0].id
      : product.id.toString(),
    category: productExtended.attributes.product_type || '',
    name: product.slug,
    brand: productExtended.attributes.brand?.name || '',
    price: stringToPrice(
      productExtended.pricing.discounted_price?.price_breakdown.price.amount ||
        productExtended.pricing.original_price.price_breakdown.price.amount
    ),
    currency: productExtended.pricing.currency_name,
    url: `https://www.depop.com/products/${product.slug}/`,
    quantity: 1,
    google_business_vertical: 'retail',
  };
}
