'use client';

import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { Button } from '@depop/web-ui-kit/Button';
import { RearrangeIcon } from '@depop/web-ui-kit/Icons/RearrangeIcon';
import { MoveUpAndDownIcon } from '@depop/web-ui-kit/Icons/MoveUpAndDownIcon';
import { PrimaryTabs } from '@depop/web-ui-kit/PrimaryTabs';

import { NavigationTab } from '../NavigationTab/index.tsx';

import styles from './styles.module.css';

import { ShopByUsernameResponse } from '@/modules/shop/types';
import { useActiveShopTab } from '@/modules/shop/hooks/useActiveShopTab';
import { useIsViewingOwnShop } from '@/modules/shop/hooks/useIsViewingOwnShop';
import { useRearrangeProducts } from '@/modules/shop/hooks/useRearrangeProducts';
import { ShopProfileSection } from '@/modules/activityTracker/constants';
import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';
import { useExtendedRouter } from '@/modules/routing/useExtendedRouter';

type Props = {
  seller: ShopByUsernameResponse;
};

export function ShopNavigation({ seller }: Props) {
  const tCommon = useTranslations('common');
  const tShop = useTranslations('shop');
  const isViewingOwnShop = useIsViewingOwnShop();
  const { tab } = useActiveShopTab();
  const router = useExtendedRouter();

  const {
    rearrangeableProductList,
    isRearrangeModeSupported,
    isRearrangeModeActive,
    handleRearrangeInitialiseClick,
    handleSaveRearrangeClick,
    handleMoveSoldClick,
    handleCancelRearrangeClick,
    isRearrangedListSaving,
    isRearrangedListSaved,
  } = useRearrangeProducts(seller.id);

  const isSellingSoldPage = [
    ShopProfileSection.Selling,
    ShopProfileSection.Sold,
  ].includes(tab);

  const { web_6224_sold_items_bottom_of_the_shop } = useExperiments([
    'web_6224_sold_items_bottom_of_the_shop',
  ]);

  const isSoldItemsOnTheBottomOfShop =
    web_6224_sold_items_bottom_of_the_shop === VARIANT_IDENTIFIER;

  function handleTabChange(sectionName: ShopProfileSection) {
    if (sectionName === ShopProfileSection.Shop) {
      router.push(`/${seller.username}/`);
    } else {
      router.push(`/${seller.username}/${sectionName}/`);
    }
  }

  const tabs = isViewingOwnShop
    ? [
        {
          id: ShopProfileSection.Shop,
          label: tShop('Selling'),
          value: ShopProfileSection.Shop,
        },
        {
          id: ShopProfileSection.Likes,
          label: tShop('Likes'),
          value: ShopProfileSection.Likes,
        },
        {
          id: ShopProfileSection.Saved,
          label: tShop('Saves'),
          value: ShopProfileSection.Saved,
        },
      ]
    : [
        {
          id: ShopProfileSection.Shop,
          label: tShop('Shop'),
          value: ShopProfileSection.Shop,
        },
        {
          id: ShopProfileSection.Likes,
          label: tShop('Likes'),
          value: ShopProfileSection.Likes,
        },
      ];

  if (isSoldItemsOnTheBottomOfShop) {
    return (
      <div className={clsx(styles.wrapper, styles.tabsWrapper)}>
        <PrimaryTabs
          tabs={tabs}
          activeValue={tab}
          name="shop-navigation-tabs"
          className={styles.primaryTabs}
          onChange={(e: ChangeEvent) => {
            handleTabChange(e.target.id as ShopProfileSection);
          }}
        />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabsContainer}>
        <div className={styles.navigationTabsContainer}>
          <NavigationTab
            isActive={tab === ShopProfileSection.Shop}
            href={`/${seller.username}/`}
          >
            {tCommon('All')}
          </NavigationTab>
          <NavigationTab
            isActive={tab === ShopProfileSection.Selling}
            href={`/${seller.username}/selling/`}
          >
            {tShop('Selling')}
          </NavigationTab>
          <NavigationTab
            isActive={tab === ShopProfileSection.Sold}
            href={`/${seller.username}/sold/`}
          >
            {tShop('Sold')}
          </NavigationTab>
          <NavigationTab
            isActive={tab === ShopProfileSection.Likes}
            href={`/${seller.username}/likes/`}
          >
            {tShop('Likes')}
          </NavigationTab>
          {isViewingOwnShop && (
            <NavigationTab
              isActive={tab === ShopProfileSection.Saved}
              href={`/${seller.username}/saved/`}
            >
              {tShop('Saved')}
            </NavigationTab>
          )}
        </div>

        {isSellingSoldPage && (
          <Text type="caption1">{tShop('SortedByCreation')}</Text>
        )}
      </div>

      {isRearrangeModeSupported && (
        <div className={styles.actionButtonsContainer}>
          {isRearrangeModeActive ? (
            <>
              <Button
                className={clsx(
                  styles.actionButton,
                  styles.rearrangeModeActiveButton
                )}
                onClick={() => handleCancelRearrangeClick(true)}
                disabled={isRearrangedListSaving}
              >
                {tShop('Cancel')}
              </Button>
              <Button
                className={clsx(
                  styles.actionButton,
                  styles.rearrangeModeActiveButton
                )}
                onClick={handleSaveRearrangeClick}
                isLoading={isRearrangedListSaving}
                isSuccess={isRearrangedListSaved}
                disabled={isRearrangedListSaving || isRearrangedListSaved}
              >
                {tShop('Save')}
              </Button>
            </>
          ) : (
            <>
              <div className={styles.rearrangeButtons}>
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.rearrangeModeInactiveButton
                  )}
                  onClick={handleRearrangeInitialiseClick}
                  disabled={!rearrangeableProductList?.length}
                >
                  <RearrangeIcon className={styles.rearrangeAndMoveIcons} />
                  {tShop('Rearrange')}
                </Button>
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.rearrangeModeInactiveButton
                  )}
                  onClick={handleMoveSoldClick}
                  isLoading={isRearrangedListSaving}
                  isSuccess={isRearrangedListSaved}
                  disabled={isRearrangedListSaving || isRearrangedListSaved}
                >
                  <MoveUpAndDownIcon className={styles.rearrangeAndMoveIcons} />
                  {tShop('MoveItems')}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
